import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import {
  Path,
  PathMethod,
  PathVariable,
  RequestBody,
  RequestParams,
} from './api-types';
import { environment } from '../../../environments/environment';

// export const baseUrl = 'http://13.234.149.108/preparedfoodapi/api';
export const baseUrl = environment.baseUrl;

@Injectable({ providedIn: 'root' })
export class ApiService {
  client = inject(HttpClient);
  get(url: string) {
    return this.client.get(url);
  }
  callApi = <P extends Path, M extends PathMethod<P>>(
    url: P,
    method: M,
    ...params:
      | (RequestParams<P, M> extends undefined ? [] : [RequestParams<P, M>])
      | (RequestBody<P, M> extends undefined ? [] : [RequestBody<P, M>])
      | (RequestBody<P, M> extends undefined ? [] : [PathVariable<P, M>])
  ) => {
    let call: Observable<any> = EMPTY;
    switch (method) {
      case 'get': {
        const queryParams =
          params[0] && (params[0] as RequestParams<P, M>)['query'];
        const pathVariable =
          params[0] && (params[0] as PathVariable<P, M>)['path'];

        const regex = /{([^}]+)}/g;
        const endpoint = pathVariable
          ? (baseUrl + url).replace(regex, (_, key) => {
              const value = pathVariable[key.trim()];
              return value !== undefined ? String(value) : key;
            })
          : baseUrl + url;

        // console.log(pathVariable);

        call = this.client.get(
          endpoint,
          queryParams ? { params: queryParams } : { params: null }
        );
        break;
      }
      case 'post': {
        const body =
          params[0] &&
          (params[0] as RequestBody<P, M>)['content']['application/json'];
          // console.log(body);
          
        call = this.client.post(baseUrl + url, body ? body : {});
        break;
      }
      case 'put': {
        const body =
          params[0] &&
          (params[0] as RequestBody<P, M>)['content']['application/json'];
        call = this.client.put(baseUrl + url, body ? body : {});
        break;
      }
    }
    return call;
  };
  downloadFileRequest = <P extends Path, M extends PathMethod<P>>(
    url: P,
    method: M,
    body?: any
  ) => {
    return method === 'get'
      ? this.client.get(baseUrl + url, { responseType: 'blob' })
      : this.client.post(baseUrl + url, body, { responseType: 'blob' });
  };

  uploadFile = <P extends Path, M extends PathMethod<P>>(
    url: P,
    metod: M,
    formData: FormData
  ) => {
    return this.client.post(baseUrl + url, formData);
  };
  // downloadFileRequest = <P extends Path, M extends PathMethod<P>>(
  //   url: P,
  //   method: M,
  //   body?: any
  // ) => {
  //   return method === 'get'
  //     ? this.client.get(this.baseUrl + url, { responseType: 'blob' })
  //     : this.client.post(this.baseUrl + url, body, { responseType: 'blob' });
  // };

  // uploadFile = <P extends Path, M extends PathMethod<P>>(
  //   url: P,
  //   metod: M,
  //   formData: FormData
  // ) => {
  //   return this.client.post(this.baseUrl + url, formData);
  // };

  // downloadLink = (url: string) => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   const date = new Date();
  //   link.download = 'Products_' + date.toLocaleDateString('en-IN');
  //   link.click();
  // };
  public downloadExcel(res: Blob, fileName: string) {
    const blob = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  public downloadPdf(res: Blob, fileName: string) {
    const blob = new Blob([res], {
      type: 'application/pdf',
    });
    const link = document.createElement('a');
    link.target = '_blan';
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  donwnload(file: any) {
    if (!file) return;
    const link = document.createElement('a');
    link.href = file.fileUrl || '';
    const date = new Date();
    link.download = file.mediaName || '';
    link.click();
  }
}
